import { getMonthStart } from "../../../../../utils/DateUtils";

function SchedQtyDetailReport({
    gasFlowDate = getMonthStart(new Date()),
    selectedContract = {},
    selectedSupplier = {},
}) {
    const curTime = new Date().toLocaleString("en-us");
    
    let fixedLocalTime = new Date(gasFlowDate);
    fixedLocalTime.setDate(fixedLocalTime.getDate() + 1);
    fixedLocalTime = getMonthStart(fixedLocalTime);

    const accountMonth = fixedLocalTime.toLocaleDateString('en-us', { month: 'numeric', year: 'numeric' });

    return (
        <>
            <div className="container-fluid">
                <div className="row relaxHeight">
                    <div className="col">
                        Run D/T: {curTime}
                    </div>
                </div>
                <div className="row compactHeight">
                    <div className="col">
                        Production Month: {accountMonth}    
                    </div>                    
                </div>
                <div className="row compactHeight">
                    <div className="col">
                        Business Party: None    
                    </div>  
                </div>
                <div className="row relaxHeight">
                    <div className="col">
                        Contract: {selectedContract?.CNTR_ALT_NUM1}
                    </div>
                </div>
                <div className="row relaxHeight">
                    <div className="col-lg-4 col-md-4 col-sm-12 text-start bold">
                        Note: Scheduled quantities are as of the current confirmation gas day and cycle
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 text-center bold">
                        Scheduled Quantity Detail Report
                    </div>
                </div>
                <div className="row compactHeight">
                    <div className="col bold">
                        Service Requester: {selectedSupplier?.ACCT_ID} - {selectedSupplier?.NAME}
                    </div>  
                </div>
                <div className="row relaxHeight">
                    <div className="col bold">
                        PT Nominations
                    </div>
                </div>
            </div>
        </>
    );
}

export default SchedQtyDetailReport