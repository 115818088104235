
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ScheduledQuantityDetailTable from "../../Tables/ScheduledQuantityDetailTable";
import { getNumberDaysOfMonth, getDateObject, isSameDate } from "../../../../../utils/DateUtils";

let totalMonthlyNom = {
    isTotalNom: true,
    PACKAGE_NUM: '000total',
    ReceiptID: '',
    ReceiptName: 'Total',
    DeliveryID: '',
    DeliveryName: 'Total',
    pkgID: '',
    receiverPkgPath: { ENT_PKG_VOL_DTL: []},
    deliveryPkgPath: { ENT_PKG_VOL_DTL: []}
}

const generateTotalMonthlyNom = ({totalMonthlyNom, gasFlowDate, MonthlyReceiverPkgPath, MonthlyDeliveryPkgPath}) => {
    let receiverPkgVolDtl = [];
    let deliveryPkgVolDtl = [];
    const daysMonth = getNumberDaysOfMonth(gasFlowDate);
    let gasFlowDateObj;
    let year;
    let month;
    if (getDateObject(gasFlowDate) == null) { // if getdateobject doesn't get expected string format it returns null, gasflowdate turns out can be parsed by new date correctly
        gasFlowDateObj = new Date(gasFlowDate);
        year = gasFlowDateObj.getUTCFullYear();
        month = gasFlowDateObj.getUTCMonth();
    } else {
        gasFlowDateObj = getDateObject(gasFlowDate)
        year = 1900 + gasFlowDateObj.getYear();
        month = gasFlowDateObj.getMonth();
    }
    
    for (let day = 1; day <= daysMonth; day++) {
        const curGasFlowDate = new Date(year, month, day).toISOString();
        receiverPkgVolDtl.push({
            GASFLOW_DATE: curGasFlowDate,
            NOM_RCV_VOL: 0,
        })

        deliveryPkgVolDtl.push({
            GASFLOW_DATE: curGasFlowDate,
            NOM_DLV_VOL: 0,
        })
    }
    
    if (!Array.isArray(MonthlyReceiverPkgPath) 
        || !Array.isArray(MonthlyDeliveryPkgPath) 
        || MonthlyReceiverPkgPath.length === 0
        || MonthlyDeliveryPkgPath.length === 0) return totalMonthlyNom
    
    MonthlyReceiverPkgPath.forEach(path => {
        if (!Array.isArray(path.ENT_PKG_VOL_DTL) || path.ENT_PKG_VOL_DTL.length === 0) return;
        
        path.ENT_PKG_VOL_DTL.forEach(monthlyPath => {
            const idx = receiverPkgVolDtl.findIndex( recPath => isSameDate(recPath.GASFLOW_DATE, monthlyPath.GASFLOW_DATE));
            if (idx !== -1) receiverPkgVolDtl[idx].NOM_RCV_VOL += monthlyPath.NOM_RCV_VOL ? monthlyPath.NOM_RCV_VOL : 0;
        });
    });

    MonthlyDeliveryPkgPath.forEach(path => {
        if (!Array.isArray(path.ENT_PKG_VOL_DTL) || path.ENT_PKG_VOL_DTL.length === 0) return;
        
        path.ENT_PKG_VOL_DTL.forEach(monthlyPath => {

            const idx = deliveryPkgVolDtl.findIndex(delPath => isSameDate(delPath.GASFLOW_DATE, monthlyPath.GASFLOW_DATE) || delPath.GASFLOW_DATE === monthlyPath.GASFLOW_DATE);
            if (idx !== -1) deliveryPkgVolDtl[idx].NOM_DLV_VOL += monthlyPath.NOM_DLV_VOL ? monthlyPath.NOM_DLV_VOL : 0;
        });
    });

    totalMonthlyNom.receiverPkgPath.ENT_PKG_VOL_DTL = receiverPkgVolDtl;
    totalMonthlyNom.deliveryPkgPath.ENT_PKG_VOL_DTL = deliveryPkgVolDtl;

    return totalMonthlyNom;
}

function DailyQtyReport ({ gasFlowDate = new Date(), selectedContract }) {
    let MonthlyReceiverPkgPath = [];
    let MonthlyDeliveryPkgPath = [];

    const monthlyNoms = useSelector(state => state.non_path_noms_slice.modalStates.schedQtyDetailModalState?.monthlyNoms);
    const [report, setReport] = useState(<></>);

    useEffect( () => {
        if (!Array.isArray(monthlyNoms) || monthlyNoms.length === 0) return;
        const monthlyNomsWithTotal = monthlyNoms.slice();
        
        monthlyNomsWithTotal.push(totalMonthlyNom);
        
        const report = monthlyNomsWithTotal.map( nom => {
            if (typeof nom.isTotalNom === "undefined") {
                const paths = nom.ENT_PKG_PATH;
                const activities = nom.ACTIV_NUM?.ENT_ACTIVITY_NONPATH;
                
                if (!Array.isArray(paths) || !Array.isArray(activities)) return (<></>);
                
                const receiver = activities.find((actiivity) => {
                    return actiivity.PT_DIR_FLOW === 'R';
                });

                const delivery = activities.find((actiivity) => {
                    return actiivity.PT_DIR_FLOW === 'D';
                });

                const receiverPkgPath = paths.find( path => {
                    return path.DLV_PT_NUM == null;
                });

                const deliveryPkgPath = paths.find( path => {
                    return path.DLV_PT_NUM != null;
                });

                const pkgID = Array.isArray(receiverPkgPath?.ENT_PKG_PATH_DTL) && receiverPkgPath?.ENT_PKG_PATH_DTL?.length > 0
                                ? receiverPkgPath.ENT_PKG_PATH_DTL[0].UP_CNTR 
                                : "";
                
                MonthlyReceiverPkgPath.push(receiverPkgPath);
                MonthlyDeliveryPkgPath.push(deliveryPkgPath);
                
                return (
                    <div key={nom.PACKAGE_NUM} className="container-fluid">
                        <div className="row">
                            <div className="col bold">
                                Svc Req K: {selectedContract?.CNTR_ALT_NUM1} Current Business
                            </div>
                        </div>
                        <div className="row">
                            <div className="col bold">
                                Rec Loc: {receiver?.ReceiptID} - {receiver?.ReceiptName}; Del Loc: {delivery?.DeliveryID} - {delivery?.DeliveryName}, Dn K: {pkgID}
                            </div>
                        </div>
                        <ScheduledQuantityDetailTable
                            gasFlowDate={gasFlowDate}
                            receiverPkgPath={receiverPkgPath}
                            deliveryPkgPath={deliveryPkgPath}
                        />
                        <div className="row splitter">&nbsp;</div>
                    </div>
                )
            }
            else {
                const totalMonthlyNom = generateTotalMonthlyNom({
                    totalMonthlyNom: nom,
                    gasFlowDate: gasFlowDate,
                    MonthlyReceiverPkgPath: MonthlyReceiverPkgPath,
                    MonthlyDeliveryPkgPath: MonthlyDeliveryPkgPath
                })

                return (
                    <div key={nom.PACKAGE_NUM} className="container-fluid">
                        <div className="row">
                            <div className="col bold">
                                Svc Req K: {selectedContract?.CNTR_ALT_NUM1} Current Business
                            </div>
                        </div>
                        <div className="row">
                            <div className="col bold">
                                Rec Loc: {totalMonthlyNom?.ReceiptID} - {totalMonthlyNom?.ReceiptName}; Del Loc: {totalMonthlyNom?.DeliveryID} - {totalMonthlyNom?.DeliveryName}, Dn K: {totalMonthlyNom?.pkgID}
                            </div>
                        </div>
                        <ScheduledQuantityDetailTable
                            gasFlowDate={gasFlowDate}
                            receiverPkgPath={totalMonthlyNom.receiverPkgPath}
                            deliveryPkgPath={totalMonthlyNom.deliveryPkgPath}
                        />
                        <div className="row splitter">&nbsp;</div>
                    </div>
                )
            }
        });
        setReport(report);
    }, [monthlyNoms])
    
    return (
        <>
            {report}
        </>
    );
};

export default DailyQtyReport