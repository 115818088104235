import React from 'react';
import DataTable from 'react-data-table-component';
import { getNumberDaysOfMonth, isValidDateString } from '../../../../../utils/DateUtils';

function ScheduledQuantityDetailTable({ gasFlowDate = new Date(), receiverPkgPath = {}, deliveryPkgPath = {}}) {
    const daysMonth = getNumberDaysOfMonth(gasFlowDate);
    
    // Set up col headers
    const cols = [
        {
            name: 'Date',
            selector: row => row.QtySubject,
            sortable: false,
            compact: true,
            wrap: true,
            cell: row => <div style={{ fontWeight: 'bold' }}>{row.QtySubject}</div>,
            width: '124px'
        },
    ]

    for (let day = 1; day <= daysMonth; day++) {
        cols.push({
            name: day.toString(),
            selector: row => row[`day${day}`],
            sortable: false,
            compact: true,
            wrap: true,
            width: '41.5px'
        });
    }
    cols.push({
        name: 'Total',
        selector: row => row.Total,
        sortable: false,
        compact: true,
        wrap: true,
    })

    // Set up data
    const populateDayQty = (partyPkgPath, isReceiver = false) => {
        var DayQtys = {};
        
        // Initialize
        for (let day = 1; day <= daysMonth; day++) DayQtys[`day${day}`] = '';
        let total = 0;

        // Populate noms qty by date
        if (Array.isArray(partyPkgPath.ENT_PKG_VOL_DTL) && partyPkgPath.ENT_PKG_VOL_DTL.length > 0) {
            const vols = partyPkgPath.ENT_PKG_VOL_DTL;
            vols.forEach((vol, idx) => {
                const dayOfMonth = isValidDateString(vol.GASFLOW_DATE) ? new Date(vol.GASFLOW_DATE).getDate() : idx + 1;
                const qty = isReceiver ? (vol.NOM_RCV_VOL ? vol.NOM_RCV_VOL : 0) : (vol.NOM_DLV_VOL ? vol.NOM_DLV_VOL : 0)
                
                DayQtys[`day${dayOfMonth}`] = qty.toLocaleString();
                total += qty;
            });
        }

        DayQtys['Total'] = total?.toLocaleString();
        return DayQtys;
    }

    var data = [];
    const receiverDayQtys = populateDayQty(receiverPkgPath, true);
    const deliveryDayQtys = populateDayQty(deliveryPkgPath, false);

    data.push({...receiverDayQtys, QtySubject: 'Nominated Rec Qty'});
    data.push({...deliveryDayQtys, QtySubject: 'Nominated Del Qty'});
    
    return (
        <DataTable
			columns={cols}
			data={data}
			fixedHeaderScrollHeight="500px"
			dense={true}
			highlightOnHover={true}
			pointerOnHover={true}
			striped={true}
		/>
    );
}

export default ScheduledQuantityDetailTable;