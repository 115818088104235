/*
 *
 * File for creating custom date functions.
 * Date: 12/07/2022
 *
 */

/**
 * Check if object is string
 */
export function isString(str) {
  return Object.prototype.toString.call(str) === '[object String]';
}

/**
 * Check if object is number
 * @param {*} num
 * @returns
 */
export function isNumber(num) {
  return typeof num === 'number';
}

/**
 * Check if object is date
 */
export function isDateType(date) {
  return date instanceof Date;
}

/**
 * Return Date object
 */
export function getDateObject(date) {
  if (isString(date)) {
    if (!isValidDateString(date)) return null;
    var datePart = date.split('T')[0];
    var dateArray = datePart.split('-');
    if (dateArray.length < 3) return null;
    return new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );
  } else if (isDateType(date)) {
    return date;
  } else {
    return null;
  }
}

/**
 * Return Date String
 */
export function getDateString(date) {
  if (isString(date) && isValidDateString(date)) {
    return date;
  } else if (isDateType(date)) {
    return date.toDateString();
  } else {
    return null;
  }
}

/**
 * Return ISO String
 */
export function getISODateString(date) {
  if (isString(date) && isValidDateString(date)) {
    return new Date(date).toISOString();
  } else if (isDateType(date)) {
    return date.toISOString();
  } else {
    return null;
  }
}

/**
 * Return Date String without Time
 */
export function getDateStringWithoutTime(date) {
  if (isString(date) && isValidDateString(date)) {
    return date.substring(0, 10);
  } else if (isDateType(date)) {
    return date.toISOString().substring(0, 10);
  } else {
    return null;
  }
}

/**
 * Return true if equal Date
 */
export function isSameDate(date1, date2) {
  const dateObj1 = getDateObject(date1);
  const dateObj2 = getDateObject(date2);

  const sameYear = dateObj1.getYear() === dateObj2.getYear();
  const sameMonth = dateObj1.getMonth() === dateObj2.getMonth();
  const sameDate = dateObj1.getDate() === dateObj2.getDate();

  if (sameYear && sameMonth && sameDate) {
    return true;
  } else {
    return false;
  }
}

/**
 * Check if valid date string
 */
export function isValidDateString(dateStr) {
  return new Date(dateStr) !== 'Invalid Date' && !isNaN(new Date(dateStr));
}

/**
 * Check if object is date object
 */
export function isValidDate(date) {
  return (
    date &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date)
  );
}

/**
 * Converts a date to a date string that can be passed into an html date picker.
 * @param {any} date
 */
export function toDatePickerString(date) {
  if (!date) return;
  let dateString = new Date(date).toISOString();
  dateString = dateString.slice(0, dateString.indexOf('T'));
  return dateString;
}

export const setTimeToNine = (date) => {
  let newDate = new Date(date);
  newDate.setUTCHours(9, 0, 0, 0);
  return newDate;
};

/**
 * Converts a date to a date string that can be passed into an html month date picker.
 * @param {any} date
 */
export function toDatePickerMonthString(date) {
  if (!date) return;
  let newDate;
  if (typeof date === 'string') {
    newDate = new Date(date);
  } else {
    newDate = date;
  }
  let year = newDate.getUTCFullYear();
  let month = newDate.getUTCMonth() + 1;
  month = month < 10 ? '0' + month : month;
  let dateString = year + '-' + month;
  return dateString;
}

/**
 * Converts a date to a date string that can be passed into an html time date picker.
 * @param {any} date
 */
export function toDatePickerTimeString(date) {
  if (!date) return;
  let newDate;
  if (typeof date === 'string') {
    newDate = new Date(date);
  } else {
    newDate = date;
  }
  const hour = newDate.getHours();
  const hourString = hour.toString().length === 1 ? `0${hour}` : hour;
  const minute = newDate.getMinutes();
  const minuteString = minute.toString().length === 1 ? `${minute}0` : minute;
  const dateString = `${hourString}:${minuteString}`;
  return dateString;
}

/**
 * Converts a date to a date string that can be passed into an html datetime-local date picker.
 * @param {any} date
 */
export function toDatePickerDateTimeString(date) {
  //console.log('date: ', date);
  //console.log('date instanceof Date: ', date instanceof Date);
  //console.log('date typeof string: ', typeof (date) === 'string');
  if (!date || (typeof date !== 'string' && !(date instanceof Date)))
    throw new Error(
      'You must pass in either a string or a Date object to this function.'
    );

  let newDate = typeof date === 'string' ? new Date(date) : date;

  /*
	const year = newDate.getFullYear();
	const month = newDate.getMonth() + 1;
	const monthString = month.toString().length === 1 ? `0${month}` : month;
	const day = newDate.getDay();
	const dayString = day.toString().length === 1 ? `0${day}` : day;
	const hour = newDate.getHours();
	const hourString = hour.toString().length === 1 ? `0${hour}` : hour;
	const minute = newDate.getMinutes();
	const minuteString = minute.toString().length === 1 ? `${minute}0` : minute;
	const dateString = `${year}-${monthString}-${dayString}T${hourString}:${minuteString}`;
	return dateString;
	*/

  return newDate.toISOString().slice(0, 16);
}

/**
 * Gets the next month start of any date passed in.
 * @param {any} date
 */
export function getNextMonthStart(date) {
  let newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + 1, 1);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

/**
 * Gets the next month end of any date passed in.
 * @param {any} date
 */
export function getNextMonthEnd(date) {
  if (!date) return;
  let newDate;
  if (typeof date == 'string') {
    newDate = new Date(date);
  } else {
    newDate = date;
  }
  let year = newDate.getUTCFullYear();
  let monthAfterNext = newDate.getUTCMonth() + 2;
  return new Date(year, monthAfterNext, 0);
}

/**
 * Gets the last day of the month for any date passed in.
 * @param {any} date
 */
export function getMonthEnd(date) {
  if (!date) return;
  let newDate;
  if (typeof date === 'string') newDate = new Date(date);
  else newDate = date;
  let year = newDate.getUTCFullYear();
  let nextMonth = newDate.getUTCMonth() + 1;
  return new Date(year, nextMonth, 0);
}

/**
 * Gets the first day of the month for any date passed in.
 * @param {any} date
 */
export function getMonthStart(date) {
  if (!date) return;
  let newDate;
  if (typeof date === 'string') newDate = new Date(date);
  else newDate = date;
  let year = newDate.getUTCFullYear();
  let month = newDate.getUTCMonth();
  newDate = new Date(year, month, 1);
  return newDate;
}

/**
 * Gets the next day of any date that is passed in.
 * @param {any} date
 */
export function getNextDay(date) {
  if (!date) return;
  let newDate;
  if (typeof date === 'string') {
    newDate = new Date(date);
  } else {
    newDate = date;
  }
  let year = newDate.getUTCFullYear();
  let month = newDate.getUTCMonth();
  let nextDay = newDate.getUTCDate() + 1;
  return new Date(year, month, nextDay);
}

/**
 * Gets the day n days from the date that is passed in, where n is equal to the number
 * of days passed in.
 * @param {any} date
 * @param {any} n
 */
export function getDay(date, n) {
  if (!date) return;
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate() + n;
  return new Date(year, month, day);
}

/**
 * Gets the start of the day for any date passed in.
 * @param {any} date
 */
export function getDayStart(date) {
  if (!date) return;
  let newDate = reverseOffset(new Date(date));
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

const reverseOffset = (date) => {
  let newDate = new Date(date);
  // Get the original offset in minutes
  let originalOffsetMinutes = newDate.getTimezoneOffset();
  // Add the offset in minutes to the date
  newDate.setMinutes(newDate.getMinutes() + originalOffsetMinutes);
  return newDate;
};

/**
 * Method to convert the date into a string that will save in the database
 * for the correct time.
 * @param {any} date
 */
export function toISOStringForDatabase(date) {
  if (!date) return;
  let newDate;
  if (typeof date === 'string') {
    newDate = new Date(date);
    const day = newDate.getUTCDate();
    const month = newDate.getUTCMonth();
    const year = newDate.getUTCFullYear();
    newDate = new Date(year, month, day);
  } else {
    newDate = date;
  }
  let dateString = newDate.toString();
  dateString = dateString.slice(0, dateString.indexOf('-'));
  const returnDate = new Date(dateString).toISOString();
  return returnDate;
}

/**
 * Method for converting date to short date string.
 * @param {any} date
 */
export function toShortDate(date) {
  if (!date) return;
  let newDate;
  if (typeof date === 'string') newDate = new Date(date);
  else newDate = date;
  let year = newDate.getUTCFullYear();
  let month = newDate.getUTCMonth() + 1;
  let day = newDate.getUTCDate();
  return month + '/' + day + '/' + year;
}

/**
 * Formats a date-time for display.
 * @param {any} date
 */
export function formatDateTime(date) {
  let newDate = date instanceof Date ? date : new Date(date);
  return newDate.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
}

/**
 * Formats a date for display.
 * @param {any} date
 */
export function formatDate(date) {
  if (!date) return;
  let newDate = date instanceof Date ? date : new Date(date);
  return newDate.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
}

export function getNumberOfDays(date1, date2) {
  if (!date1 || !date2) return;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  let newDate1 = date1;
  let newDate2 = date2;

  if (typeof newDate1 === 'string') newDate1 = new Date(newDate1);
  if (typeof newDate2 === 'string') newDate2 = new Date(newDate2);

  const diffDays = Math.round(Math.abs((newDate1 - newDate2) / oneDay)) + 1;
  return diffDays;
}

export function getNumberDaysOfMonth(date) {
  if (isString(date)) {
    if (!isValidDateString(date)) return 0;
    return getMonthEnd(new Date(date)).getDate();
  } else {
    if (!isValidDate(date)) return 0;
    return getMonthEnd(date).getDate();
  }
}

export const endDateHandler = (month, year) => {
  if (!month || !year) return 30;
  const days = [
    31,
    year % 4 === 0 ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  return days[month].toString();
};

export const getFromAndToData = (data, fromDate, toDate) => {
  return data
    .filter((x) => {
      return (
        !x?.date_key ||
        (x?.date_key &&
          Date.parse(x.date_key) >=
            Date.parse(
              `${new Date(fromDate).getMonth() + 1}/01/${new Date(
                fromDate
              ).getFullYear()}`
            ) &&
          Date.parse(x.date_key) <=
            Date.parse(
              `${new Date(toDate).getMonth() + 1}/${endDateHandler(
                new Date(toDate).getMonth(),
                new Date(toDate).getFullYear()
              )}/${new Date(toDate).getFullYear()}`
            ))
      );
    })
    .filter(
      (v, i, a) =>
        a
          .map((x) =>
            Date.parse(
              `${new Date(x.date_key).getMonth() + 1}/01/${new Date(
                x.date_key
              ).getFullYear()}`
            )
          )
          .indexOf(
            Date.parse(
              `${new Date(v.date_key).getMonth() + 1}/01/${new Date(
                v.date_key
              ).getFullYear()}`
            )
          ) === i
    );
};

export const getDataInDateRange = (data, item) => {
  return data.filter((x) => {
    return (
      !x?.date_key ||
      (x?.date_key &&
        Date.parse(x.date_key) >=
          Date.parse(
            `${new Date(item.date_key).getMonth() + 1}/01/${new Date(
                item.date_key
            ).getFullYear()}`
          ) &&
          (Date.parse(x.date_key) - 1000 * 60 * 60 * 24) <=
          Date.parse(
              `${new Date(item.date_key).getMonth() + 1}/${endDateHandler(
                  new Date(item.date_key).getMonth(),
                  new Date(item.date_key).getFullYear()
              )}/${new Date(item.date_key).getFullYear()}`
          ))
    );
  });
};
